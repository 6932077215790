import placeholder from '~/assets/images/placeholder.png';
import { Banner, Carousel, Typography } from '../ui';
import { cn } from '~/lib/utils';
import { Link } from '@remix-run/react';

interface LandingFeatureProps {
  className?: string;
  banners: Array<LandingFeatureModel>;
  id?: string;
}

export interface LandingFeatureModel {
  title: string;
  description: string;
  scr: string;
  to?: string;
  buttonText: string;
}

export function LandingFeature(props: LandingFeatureProps) {
  const landingArray = props.banners;
  return (
    <div className={cn(props.className, 'md:mb-12')} id={props.id}>
      <Carousel
        slidesPerView={1}
        autoplay
        loop
        spaceBetween={0}
        grabCursor
      >
        {landingArray.map((landing, index) => (
          <LandingFeatureItem
            title={landing.title}
            description={landing.description}
            scr={landing.scr}
            to={landing.to}
            buttonText={landing.buttonText}
            key={`${index + 1}`}
          />
        ))}
      </Carousel>
    </div>
  );
}

const LandingFeatureItem = ({
  title,
  description,
  scr,
  to,
  buttonText
}: {
  title: string;
  description: string;
  scr: string;
  to?: string;
  buttonText: string;
}) => {
  return (
    <Banner
      overlay
      overlayClassName='from-[rgba(0,0,0,0.8)]'
      srcImage={scr || placeholder}
      altImage="Landing collection banner image">
      <div className="absolute z-10 top-0 flex-col flex justify-center items-start text-left h-full px-nav">
        <Typography variant="headline3" className="text-white">
          {title}
        </Typography>
        <Typography
          variant="body1"
          className="text-white w-10/12 md:w-9/12 md:mb-14"
        >
          {description}
        </Typography>
        <Link to={to || ''} className="h-12 md:h-16 w-[163px] md:w-64 lg:mt-0 mt-8 sm-max:px-4 sm-max:box-content rounded-md px-8  bg-cool_blue text-center hover:bg-cool_blue/80 md:py-5 text-white items-center flex justify-center" >
          <Typography variant="headline6" className='whitespace-nowrap sm-max:text-base'>{buttonText}</Typography>
        </Link>
      </div>
    </Banner>
  );
};